export const creativePhotos = [{
  id: 1,
  src: "/sample1.png",
  type: "Creative Image",
  size: "5000 x 3500 px (16.67 x 11.67 in.) - 300 dpi - RGB",
  licenseType: "Royalty-free",
  ReleaseInfo: "Model released",
}, {
  id: 2,
    src: "/sample.png",
    type: "Creative Image",
    size: "5000 x 3500 px (16.67 x 11.67 in.) - 300 dpi - RGB",
    licenseType: "Royalty-free",
    ReleaseInfo: "Model released",
},
{
  id: 3,
  src: "/sample7.jpeg",
  type: "Creative Image",
  size: "5000 x 3500 px (16.67 x 11.67 in.) - 300 dpi - RGB",
  licenseType: "Royalty-free",
  ReleaseInfo: "Model released",
},
{
  id: 4,
  src: "/sample2.png",
  type: "Creative Image",
  size: "5000 x 3500 px (16.67 x 11.67 in.) - 300 dpi - RGB",
  licenseType: "Royalty-free",
  ReleaseInfo: "Model released",

},
{
  id: 5,
  src: "/sample3.jpg",
  type: "Creative Image",
  size: "5000 x 3500 px (16.67 x 11.67 in.) - 300 dpi - RGB",
  licenseType: "Royalty-free",
  ReleaseInfo: "Model released",
}, {
  id: 6,
  src: "/sample4.jpg",
  type: "Creative Image",
  size: "5000 x 3500 px (16.67 x 11.67 in.) - 300 dpi - RGB",
  licenseType: "Royalty-free",
  ReleaseInfo: "Model released",
},
{
  id: 7,
  src: "/sample5.jpg"
},
{
  id: 8,
  src: "/sample6.jpg"
}, {
  id: 11,
  src: "/editorial1.jpg"
}, {
  id: 12,
  src: "/editorial2.jpg"
}, {
  id: 13,
  src: "/editorial3.jpg"
}, {
  id: 14,
  src: "/editorial4.jpg"
}, {
  id: 15,
  src: "/editorial5.jpg"
}, {
  id: 16,
  src: "/editorial6.jpg"
}, {
  id: 17,
  src: "/editorial7.jpg"
}, {
  id: 21,
  src: "/video4.gif"
}, {
  id: 22,
  src: "/video2.gif"
}, {
  id: 23,
  src: "/video3.gif"
},
{
  id: 23,
  src: "/video1.gif"
}
];
