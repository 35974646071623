import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none"
  },
  appBar: {
    backgroundColor: "white",
    color: "black",
  },
  image: {
    width: "100%"
  },
  body: {
    paddingTop: "60px"
  }
}));

//media type, size, license type, how can I use it?, release info
export const ShoppingCartEntry = (props) => {
  const classes = useStyles();
  const [cartItem, setCartItem] = useState({});

  useEffect(() => {
    setCartItem(props.cartItem);
  }, [props.cartItem]);

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
          Product Name
        </Typography> */}
          <Typography variant="h5" component="h2">
            <img src={cartItem.src} style={{ width: "50%" }}></img>
          </Typography>
          <Typography className={classes.pos} variant="body2">
            Media type: {cartItem.type}
          </Typography>
          <Typography className={classes.pos} variant="body2">
            Size: {cartItem.size}
          </Typography>
          <Typography className={classes.pos} variant="body2">
            License Type: {cartItem.licenseType}
          </Typography>
          <Typography className={classes.pos} variant="body2">
            How can I use it?: Available for all permitted uses todo fix this
          </Typography>
          {/* ReleaseInfo */}
          <Typography className={classes.pos} variant="body2">
            Release Info: {cartItem.ReleaseInfo}
          </Typography>
        </CardContent>

      </Card>
    </div>
  )
}