import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { creativePhotos } from '../../mock';
import { PhotoList } from '../../Pages/Photos/PhotoList';


// const useStyles = makeStyles((theme) => ({

// }));

export const Videos = () => {
  // const classes = useStyles();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const creative = creativePhotos.filter(photo => photo.src.includes('video'));
    setPhotos(creative);
  }, [])


  return (<PhotoList  data={photos} />)
}