import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { creativePhotos } from '../../mock';
import { SearchBar } from '../../Components/SearchBar';
import { CategoryMenu } from '../../Components/CategoryMenu';
import { Photos } from '../../Components/Photos';
import { Banner } from '../../Components/Banner';
import { PhotoList } from '../../Pages/Photos/PhotoList';
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  searchBar: {
    paddingTop: "60px"
  },

}));

export const Editorial = () => {
  const classes = useStyles();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const creative = creativePhotos.filter(photo => photo.src.includes('editorial'));
    setPhotos(creative);
  }, [])

  return (
    <PhotoList  data={photos} />
  )
}
