import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { creativePhotos } from '../../mock';
import { PhotoList } from '../../Pages/Photos/PhotoList';


// const useStyles = makeStyles((theme) => ({

// }));

export const Creative = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const creative = creativePhotos.filter(photo => photo.src.includes('sample'));
    setPhotos(creative);
  }, [])


  return (
    <PhotoList data={photos}/>
  )
}