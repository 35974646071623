import React from "react"
import { makeStyles } from '@material-ui/core/styles';

import { SearchBar } from '../../Components/SearchBar';
import { CategoryMenu } from '../../Components/CategoryMenu';
import { Photos } from '../../Components/Photos';
import { Banner } from '../../Components/Banner';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  searchBar: {
    paddingTop: "0px"
  },
  bold: {
    fontWeight: "bold"
  },
  left: {
    float: "left",
    paddingRight: "10px",
    fontWeight: "bold"
  },
  recentSearches: {
    float: "left",
    paddingLeft: "10px",
    // paddingRight: "10px"
    color: "gray",
    fontSize: "16px"
  },
  searchName: {
    float: "left",
    paddingLeft: "10px",
    // paddingRight: "10px"
    color: "black",
    fontSize: "16px"
  },
  right: {
    float: "right",
    color: "blue"
  },
  body: {
    paddingTop: "60px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  row: {
    // display: "inline-block"

    paddingLeft: "25px",
    paddingRight: "25px",
  },
  root: {
    paddingTop: "25px",
    flexGrow: 1,
  },
}));

export const Search = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchBar cancelButton={true} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.row}>
            <div className={classes.left}>Recent searches</div>
            <div className={classes.right}>Clear</div>
          </div>
        </Grid>

        {['Wild Life', 'June Fresh', 'Summertime'].map(recentSearches => (
          <Grid item xs={12}>
            <div className={classes.row}>
              <div className={classes.recentSearches}><ImageIcon/></div>
              <div><div className={classes.searchName}>{recentSearches}</div></div>
            </div>
          </Grid>)
        )}

        <Grid item xs={12}>
          <div className={classes.row}>
            <div className={classes.left}>Recently viewed</div>
            <div className={classes.right}>Clear</div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.row}>
            <img src="/tiny.png" style={{width: "38%"}}></img>
          </div>
        </Grid>

      </Grid>

    </div>
  )
}
