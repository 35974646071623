import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { creativePhotos  as photos } from '../../../mock';
import { PricingOption } from './PricingOptions';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    paddingTop: "60px",
    paddingBottom: "60px"
  },
  image: {
    width: "100%"
  },
  photoBody: {
    paddingLeft: "25px",
    paddingTop: "15px"
  },
  divider: {
    paddingTop: "20px",
    marginLeft: "30px",
    marginRight: "30px",
    borderBottom: "1px solid #adabab",
    marginBottom: "50px"
  }
}));
export const Detail = (props) => {
  const classes = useStyles();
  const id = props.photoId;
  const photo = photos.filter(photo => photo.id == id)[0];

  return (<div className={classes.searchBar}>
    <img src={photo.src} className={classes.image} />
    <div className={classes.photoBody}>
      <h1> Lorem Ipsum is simply dummy text - stock photo </h1>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    </div>
    {/* <div classes></div> */}
    <div className={classes.divider}> </div>
    <PricingOption />
  </div>)
}