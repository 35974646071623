import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MobilePhoto } from './MobilePhoto';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "30px"
  },
  banner: {
    width: "100%",
    padding: "60px 0",
    textAlign: "center",
    background: "#FAE9CD", //"#33cccc",
    color: "black",
    fontSize: "30px"
  },
  paper: {
    height: 140,
    width: 100,
    backgroundColor: "grey"
  },
}));

export const Photos = (props) => {
  const classes = useStyles();

  return (
    <div>
      <MobilePhoto {...props} />
    </div>
  );
}