import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menu: {
    paddingTop: "20px",
    paddingBottom: "15px",
  },
  category: {
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    cursor: "pointer"
  }
}));
export const CategoryMenu = () => {
  const classes = useStyles();
  const menu = [
    {
      name: "CREATIVE",
      path: "/"
    },
    {
      name: "EDITORIAL",
      path: "/editorial"
    },
    {
      name: "VIDEO",
      path: "/video"
    },
    {
      name: "MUSIC",
      path: "/music"
    }
  ];

  let history = useHistory();

  return (<div className={classes.menu}>
    {menu.map(menu => {
      return <span key={`category-${menu.name}`} className={classes.category}>
        <span  key={menu.name} className={classes.link} onClick={() => {
          history.push(menu.path)  }}>
          {menu.name}
        </span>
      </span>
    })}
  </div>)
}

