import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    color: "black",
  },
  image: {
    width: "100%"
  }
}));
export const MobilePhoto = (props) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div>
      {props.data.map(photo => {
        return <div key={`div-${photo.id}`}>
          <span key={photo.id} onClick={() => { history.push(`/photo/${photo.id}`)}}>
              <img src={photo.src} className={classes.image}></img>
            </span>
        </div>
      })}

    </div>
  )
}