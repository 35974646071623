import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { photos } from '../../mock';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: "30px"
  }
}));


export const ProfileMenu = () => {
  const classes = useStyles();

  return (
    <List className={classes.menu}>
      <ListItem button key="Sign in">
        {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
        <ListItemText primary="Sign in" />
      </ListItem>
      <ListItem button key="Register">
        <ListItemText primary="Register" />
      </ListItem>
      {/* <ListItem button key="Boards">
        <ListItemText primary="Boards" />
      </ListItem> */}
    </List>
  )
}


export const CategoryMenu = () => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <List className={classes.menu}>
      <ListItem button key="Creative">
        {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
        <ListItemText primary="Creative" onClick={() => {
          history.push('/')
        }}/>
      </ListItem>
      <ListItem button key="Editorial">
        <ListItemText primary="Editorial" onClick={() => {
          history.push('/editorial')
        }}/>
      </ListItem>
      <ListItem button key="Video">
        <ListItemText primary="Video" onClick={() => {
          history.push('/video')
        }}/>
      </ListItem>
      <ListItem button key="Music">
        <ListItemText primary="Music" onClick={() => {
          history.push('/music')
        }}/>
      </ListItem>
      <ListItem button key="Blog">
        <ListItemText primary="Blog" />
      </ListItem>
      <ListItem button key="Pricing">
        <ListItemText primary="Pricing" />
      </ListItem>
      {/* <ListItem button key="Boards">
        <ListItemText primary="Boards" />
      </ListItem> */}
    </List>
  )
}