import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { photos } from '../../mock';
import { ShoppingCartEntry } from './ShoppingCartEntry';
import { SearchBar } from '../../Components/SearchBar';
import Button from '@material-ui/core/Button';
import { creativePhotos } from '../../mock';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    color: "black",
  },
  image: {
    width: "100%"
  },
  body: {
    paddingTop: "60px",

  },
  shoppingCart: {
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  subtotal: {
    paddingTop: "10px",
    fontSize: "30px",
    paddingBottom: "10px",
    marginBottom: "10px"
  },
  header: {
    paddingTop: "40px",
    paddingBottom: "10px"
  },
  shoppingCartEntries: {
    // marginTop: "15px",
    border: "1px solid gray"
  },
  promo: {
    padding: "15px",
    background: "black",
    color: "grey",
    marginTop: "20px",
    marginBottom: "20px"
  },
  heading: {
    fontSize: "45px",
    marginBottom: "10px"
  }
}));

//Todo View Model for shopping cart
//revist styling
export const ShoppingCart = () => {
  const classes = useStyles();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const p = creativePhotos.filter(photos => { return photos.id < 3 })
    setPhotos(p);
  }, []);

  return (
    <div className={classes.body}>
      <SearchBar />
      <div className={classes.shoppingCart}>
        <div className={classes.header}>
          <div className={classes.heading}>Shopping cart</div>
          <div style={{ paddingBottom: "10px" }}>Items selected for purchase: {photos.length}</div>

          <div className={classes.subtotal}>Subtotal: $499.00 USD</div>

          <Button size="large" variant="contained" color="primary" href="#contained-buttons" >
            CONTINUE WITH PURCHASE
          </Button>
          <br /><br />
          <Button size="large" variant="contained" href="#contained-buttons" >
            CREATE QUOTE
        </Button>

        </div>

        <div className={classes.promo}>
          <div>Save up to $49.00 per asset by upgrading to an Ultrapack</div>
          <button style={{ textAlign: "center" }}> Save now </button>
        </div>

        <div className={classes.shoppingCartEntries}>
          {photos.map(entry => {
            return <ShoppingCartEntry cartItem={entry} />
          })}
        </div>
      </div>
    </div>
  )
}