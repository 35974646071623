import React from "react"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  banner: {
    width: "100%",
    padding: "60px 0",
    textAlign: "center",
    background: "#9df9ef", //"#33cccc",
    color: "black",
    fontSize: "30px"
  }
}));
export const Banner = () => {
  const classes = useStyles();

  return(<div className={classes.banner}> Real images from real people</div>)
}