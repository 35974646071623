import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';
import { ProfileMenu, CategoryMenu } from "./Menu"
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    color: "black",
    boxShadow: "none"
  },
  bold: {
    fontWeight: "bold",
    color: "salmon"
  },
  logo: {
    flexGrow: 1,
  },
  menuIcon: {
    paddingRight: "10px",
    paddingLeft: "10px",
    cursor: "pointer"
  },
  link: {
    // textDecoration: "none",
    // color: "black"
    cursor: "pointer"
  },
  drawer: {
    width: "300px",
    // fontSize: "20px"
  }
}));
export const Header = () => {
  const classes = useStyles();
  let history = useHistory();


  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  const list = (anchor) => {

    if (anchor === "left") {
      return <div
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        className={classes.drawer}>
        <CategoryMenu />
      </div>
    } else {
      return <div
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        className={classes.drawer}>
        <ProfileMenu />
      </div>
    }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton onClick={toggleDrawer("left", true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.logo}>

          <span className={classes.link} onClick={() => {
            history.push('/')
          }}> <span className={classes.bold}>Real</span>People co </span>
        </Typography>
        <span className={classes.menuIcon} onClick={() => {
           history.push('/shopping-cart')
        }}>  <ShoppingCartIcon /> </span>
        <span className={classes.menuIcon} onClick={toggleDrawer("right", true)} > <PersonIcon /> </span>
        {/* <Button color="inherit">Login</Button> */}
        <div>
          {['left', 'right'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      </Toolbar>
    </AppBar>
  )
}