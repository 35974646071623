import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menuBody: {
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  summary: {
    padding: "10px",
    paddingTop: "30px",
    paddingLeft: "90px",
    paddingBottom: "30px",
    fontWeight: "bold",
    fontSize: "25px"
  }
}));


const ViewCartBtn = (props) => {
  return (<Button size="large" variant="contained" color="primary" href="#contained-buttons" onClick={props.onHandleViewCart}>
    VIEW CART
  </Button>)
}

const AddToCartBtn = (props) => {
  return (<Button size="large" variant="contained" color="primary" href="#contained-buttons" onClick={props.onAddToCartBtn}>
    ADD TO CART
  </Button>)
}
export const PricingOption = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [pricing, setPricing] = useState("");
  const [addToCart, setAddToCart] = useState(false);

  const getPricing = price => {
    switch (price) {
      case "xs":
        return "$50.00";
        break;
      case "small":
        return "$175.00";
        break;
      case "medium":
        return "$375.00";
        break;
      case "large":
        return "$499.00";
        break;
    }
  }

  const handleChange = (event) => {
    const price = event.target.value;
    setPricing(price);
  };

  const AddToCart = () => {
    setTimeout(() => {
      setAddToCart(true)
    }, 1000)
  }

  const redirectToCartPage = () => {
    history.push('/shopping-cart');
  }

  const getCartButton = (addToCart) => {
    if (addToCart) {
      return <ViewCartBtn onHandleViewCart={redirectToCartPage} />;
    } else {
      return <AddToCartBtn onAddToCartBtn={AddToCart}/>;
    }
  }

  return (<div className={classes.menuBody}>
    <div> All Royalty-Free licenses include global use rights, comprehensive protection, simple pricing with volume discounts available (revist) </div>
    <FormControl component="fieldset" >
      <RadioGroup aria-label="gender" name="gender1" value={pricing} onChange={handleChange}>
        <FormControlLabel value="xs" control={<Radio />} label="Extra Small $50.00" />
        <FormControlLabel value="small" control={<Radio />} label="Small $175.00" />
        <FormControlLabel value="medium" control={<Radio />} label="Medium $375.00" />
        <FormControlLabel value="large" control={<Radio />} label="Large $499.00" />
      </RadioGroup>

      <div className={classes.summary}> {getPricing(pricing)} </div>

      {pricing ?
        <Button size="large" variant="contained" href="#contained-buttons" >
          Get this image for {getPricing(pricing)}
        </Button>
        : ''}

      <br />

      {getCartButton(addToCart)}

    </FormControl>



  </div>)
}